exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diagnostyka-js": () => import("./../../../src/pages/diagnostyka.js" /* webpackChunkName: "component---src-pages-diagnostyka-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-dojechac-js": () => import("./../../../src/pages/jak-dojechac.js" /* webpackChunkName: "component---src-pages-jak-dojechac-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leczenie-bonding-js": () => import("./../../../src/pages/leczenie/bonding.js" /* webpackChunkName: "component---src-pages-leczenie-bonding-js" */),
  "component---src-pages-leczenie-higiena-js": () => import("./../../../src/pages/leczenie/higiena.js" /* webpackChunkName: "component---src-pages-leczenie-higiena-js" */),
  "component---src-pages-leczenie-implanty-js": () => import("./../../../src/pages/leczenie/implanty.js" /* webpackChunkName: "component---src-pages-leczenie-implanty-js" */),
  "component---src-pages-leczenie-index-js": () => import("./../../../src/pages/leczenie/index.js" /* webpackChunkName: "component---src-pages-leczenie-index-js" */),
  "component---src-pages-leczenie-licowki-i-korony-js": () => import("./../../../src/pages/leczenie/licowki-i-korony.js" /* webpackChunkName: "component---src-pages-leczenie-licowki-i-korony-js" */),
  "component---src-pages-leczenie-ortodoncja-js": () => import("./../../../src/pages/leczenie/ortodoncja.js" /* webpackChunkName: "component---src-pages-leczenie-ortodoncja-js" */),
  "component---src-pages-leczenie-usuwanie-zebow-js": () => import("./../../../src/pages/leczenie/usuwanie-zebow.js" /* webpackChunkName: "component---src-pages-leczenie-usuwanie-zebow-js" */),
  "component---src-pages-leczenie-wybielanie-js": () => import("./../../../src/pages/leczenie/wybielanie.js" /* webpackChunkName: "component---src-pages-leczenie-wybielanie-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

